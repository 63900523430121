import React from 'react'
import Layout from '../components/layout'
import TechnicalWriter from '../components/categories/technical-writer'
import Seo from '../components/seo'

const TechnicalWriterPage = ({ location }) => {
  return (
    <Layout location={location}>
      <TechnicalWriter />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Technical Writer Freelancers | Codestaff'
  const description =
    'Hire the best Technica Writer freelancers at Codestaff. Get the top 1% of Technical Writer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default TechnicalWriterPage
