import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
      "@type": "Question",
      "name": "What Do You As A Technical Writer Do?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A technical writer is also known as a technical communicator that prepares journal articles, instruction manuals and other supporting documents to communicate technical and complex information more easily. Technical writers also gather and disseminate technical information among manufacturers, designers and customers."
      }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
      "@type": "Question",
      "name": "Who Needs A Technical Writer?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": ". A technical writer is needed in every industry but they are almost unnoticed because they work behind the scenes. Technical writers also help you redesign a product’s user interface because if your product documentation is poor, the product may not sell. Codestaff is your go-to technical writer."
      }
    }
}

const productMeta = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "Technical Writer",
    "image": "https://codestaff.io/technical-writer.png",
    "description": "Freelance Technical Writers found on the directory of Code Staff have all of the skill necessary to help your next project succeed. Hire a Remote Technical Writer and have them join your team.",
    "brand": "Code Staff",
    "offers": {
        "@type": "Offer",
        "url": "https://codestaff.io/technical-writer",
        "priceCurrency": "USD",
        "price": "99",
        "priceValidUntil": "2020-05-27",
        "availability": "https://schema.org/OnlineOnly",
        "itemCondition": "https://schema.org/NewCondition"
    },
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "1",
        "reviewCount": "1"
    },
    "review": {
        "@type": "Review",
        "name": "David Alleva",
        "reviewBody": "",
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "1"
        },
        "datePublished": "2020-05-06",
        "author": { "@type": "Person", "name": "David Alleva" },
        "publisher": { "@type": "Organization", "name": "Freelancer Website" }
    }
}

const TechnicalWriter = () => (
    <main>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
            <script type="application/ld+json">{JSON.stringify(productMeta)}</script>
        </Helmet>
        <section className="cx_catagories">
            <div className="container-fluid cx_top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-10">
                            <h2>
                                Hire top freelance<br /><span> Technical Writers.</span>
                            </h2>
                            <p>
                                Codestaff is a marketplace for top Technical Writers. Top
                                companies and start-ups choose Codestaff professional Technical
                                Writers for their mission critical software projects.
                            </p>
                            <Link to="/hire/" className="skills-banner-btn">
                                Hire a top Technical Writer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <CxSearch title='HIRE FREELANCE TECHNICAL WRITERS' banner='/technical-writer.png' bannerAlt='/technical writer banner' />
        </section>
        <HireAndApply />
        <TopTrendingSkills />
    </main>
)

export default TechnicalWriter